import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PAGES } from '../utils/consts';
import { getCurrentLanguage } from '../utils/language';
import AboutPage from './AboutPage/Loadable';
import ForgotPasswordPage from './ForgotPasswordPage/Loadable';
import { HomeLayout } from './Layout/HomeLayout';
import { PrimaryLayout } from './Layout/PrimaryLayout';
import { ProtectedLayout } from './Layout/ProtectedLayout';
import LoginPage from './LoginPage/Loadable';
import NotFoundPage from './NotFoundPage/Loadable';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import RecordsPage from './RecordsPage/Loadable';
import RegisterPage from './RegisterPage/Loadable';
import '../scss/main.scss';

export function App() {
  const lang = getCurrentLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    async function loadDayjsLocale() {
      if (lang === 'ru') {
        await import('dayjs/locale/ru');
      }
    }

    if (lang !== 'en') {
      loadDayjsLocale().then(() => dayjs.locale(lang));
    }
  }, [lang]);

  return (
    <>
      <Helmet
        titleTemplate={`%s - ${t('title.name')}`}
        defaultTitle={t('title.name')}
        htmlAttributes={{ lang }}
        meta={[{ name: 'description', content: t('title.description') }]}
      />

      <BrowserRouter>
        <PrimaryLayout>
          <Routes>
            <Route element={<HomeLayout />}>
              <Route path={PAGES.MAIN} element={<LoginPage />} />
              <Route path={PAGES.LOGIN} element={<LoginPage />} />
              <Route path={PAGES.REGISTER} element={<RegisterPage />} />
              <Route
                path={PAGES.FORGOT_PASSWORD}
                element={<ForgotPasswordPage />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route element={<ProtectedLayout />}>
              <Route path={PAGES.HOME} element={<RecordsPage />} />
              <Route path={PAGES.RECORDS} element={<RecordsPage />}>
                <Route path="edit/:editRecId" element={<RecordsPage />} />
                <Route path="new" element={<RecordsPage />} />
                <Route path="legend" element={<RecordsPage />} />
                <Route path=":recordId" element={<RecordsPage />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route path={PAGES.ABOUT} element={<AboutPage />} />
            <Route path={PAGES.PRIVACY} element={<PrivacyPolicyPage />} />
          </Routes>
        </PrimaryLayout>
      </BrowserRouter>
    </>
  );
}
