import { DocumentReference } from 'firebase/firestore';

export type AxisType =
  | 'love'
  | 'wealth'
  | 'hobby'
  | 'friendship'
  | 'health'
  | 'work';

export const AXIS_TYPES: Array<AxisType> = [
  'love',
  'wealth',
  'hobby',
  'friendship',
  'health',
  'work',
];

export interface RecordStatus {
  title: 'excellent' | 'good' | 'satisfactorily' | 'badly' | 'critical';
  range: string;
  color: string;
}

export interface Record {
  id: string;
  love: number;
  loveNotes?: string;
  wealth: number;
  wealthNotes?: string;
  hobby: number;
  hobbyNotes?: string;
  friendship: number;
  friendshipNotes?: string;
  health: number;
  healthNotes?: string;
  work: number;
  workNotes?: string;
  notes?: string;
  createdBy: DocumentReference;
  updatedBy: DocumentReference;
  createdAt: Date;
  updatedAt: Date;
}

// calculated fileds from Record
export interface RecordStats {
  min: number;
  max: number;
  average: number;
  diff: number;
  status: RecordStatus;
}

export type CalculatedRecord = Record & RecordStats;
