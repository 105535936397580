import {
  LogoutOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Menu, Layout, Row, Col, Avatar, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, logout } from '../../api/Firebase';
import styles from '../../scss/_colors.scss';
import { PAGES } from '../../utils/consts';
import { LanguageMenu } from './LanguageMenu';

const { Text } = Typography;

export function Header() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const { displayName, photoURL, email } = user?.providerData?.length
    ? user.providerData[0]
    : { displayName: '', photoURL: '', email: '' };

  const leftItems: MenuProps['items'] = [
    {
      label: <Link to={PAGES.RECORDS}>{t('menu.records')}</Link>,
      key: PAGES.RECORDS,
      icon: <UnorderedListOutlined />,
    },
  ];

  const rightItems: MenuProps['items'] = [
    {
      key: 'language',
      label: <LanguageMenu />,
    },
  ];

  const profileMenu: MenuProps['items'] = [
    {
      key: 'sub-menu',
      style: { height: 64 },
      label: (
        <Avatar
          size="large"
          src={photoURL}
          icon={<UserOutlined style={{ fontSize: 24 }} />}
          style={{ backgroundColor: styles.primary }}
        />
      ),
      children: [
        {
          key: 'profile',
          disabled: true,
          style: { cursor: 'default', height: displayName ? 50 : '' },
          label: displayName ? (
            <>
              <Text strong>{displayName}</Text>
              <br />
              <Text type="secondary" style={{ position: 'relative', top: -20 }}>
                {email}
              </Text>
            </>
          ) : (
            <Text type="secondary">{email}</Text>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: PAGES.RECORDS,
          icon: <UnorderedListOutlined />,
          label: (
            <Link to={PAGES.RECORDS}>
              <Text>{t('menu.records')}</Text>
            </Link>
          ),
        },
        {
          type: 'divider',
        },
        {
          key: 'logout',
          icon: (
            <Text type="danger">
              <LogoutOutlined />
            </Text>
          ),
          onClick: async () => {
            await logout();
            navigate('/');
          },
          label: <Text strong>{t('menu.logout')}</Text>,
        },
      ],
    },
  ];

  if (email) {
    rightItems.push(...profileMenu);
  }

  return (
    <Layout.Header style={{ backgroundColor: '#001529' }}>
      <Row justify="space-between">
        <Col flex="auto">
          <div style={{ marginRight: 20, float: 'left' }}>
            <Link to={PAGES.MAIN}>
              <img
                width={50}
                height={50}
                src="/images/logo_250.png"
                alt={t('title.name')}
                style={{ margin: '-6px 6px 6px 0' }}
              />
              <Text style={{ color: 'white', fontSize: '30px' }}>
                {t('title.name')}
              </Text>
            </Link>
          </div>

          {user && (
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[pathname]}
              items={leftItems}
            />
          )}
        </Col>

        <Col flex="220px">
          <Menu
            mode="horizontal"
            style={{ background: '#001529', borderBottom: 'none' }}
            items={rightItems}
          />
        </Col>
      </Row>
    </Layout.Header>
  );
}

export default Header;
