import { Card, Typography } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;

export function PrivacyPolicyPage() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('menu.privacy')} />

      <Card
        title={
          <Title style={{ textAlign: 'center' }}>
            Welcome to our Privacy Policy
          </Title>
        }
      >
        <Title level={3}>Your privacy is critically important to us.</Title>

        <Paragraph>
          It is Life Matrix&apos;s policy to respect your privacy regarding any
          information we may collect while operating our website. This Privacy
          Policy applies to{' '}
          <a href="https://lifematrix.me">https://lifematrix.me</a>
          (hereinafter, &quot;us&quot;, &quot;we&quot;, or
          &quot;https://lifematrix.me&quot;). We respect your privacy and are
          committed to protecting personally identifiable information you may
          provide us through the Website. We have adopted this privacy policy
          (&quot;Privacy Policy&quot;) to explain what information may be
          collected on our Website, how we use this information, and under what
          circumstances we may disclose the information to third parties. This
          Privacy Policy applies only to information we collect through the
          Website and does not apply to our collection of information from other
          sources.
        </Paragraph>
        <Paragraph>
          This Privacy Policy, together with the Terms and conditions posted on
          our Website, set forth the general rules and policies governing your
          use of our Website. Depending on your activities when visiting our
          Website, you may be required to agree to additional terms and
          conditions.
        </Paragraph>
        <Title level={2}>Website Visitors</Title>
        <Paragraph>
          Like most website operators, Life Matrix collects
          non-personally-identifying information of the sort that web browsers
          and servers typically make available, such as the browser type,
          language preference, referring site, and the date and time of each
          visitor request. Life Matrix&apos;s purpose in collecting
          non-personally identifying information is to better understand how A
          Plus Scheduler&apos;s visitors use its website. From time to time, A
          Plus Scheduler may release non-personally-identifying information in
          the aggregate, e.g., by publishing a report on trends in the usage of
          its website.
        </Paragraph>
        <Paragraph>
          Life Matrix also collects potentially personally-identifying
          information like Internet Protocol (IP) addresses for logged in users
          and for users leaving comments on https://lifematrix.me blog posts.
          Life Matrix only discloses logged in user and commenter IP addresses
          under the same circumstances that it uses and discloses
          personally-identifying information as described below.
        </Paragraph>
        <Title level={2}>Gathering of Personally-Identifying Information</Title>
        <Paragraph>
          Certain visitors to Life Matrix&apos;s websites choose to interact
          with Life Matrix in ways that require Life Matrix to gather
          personally-identifying information. The amount and type of information
          that Life Matrix gathers depends on the nature of the interaction. For
          example, we ask visitors who sign up for a blog at
          https://lifematrix.me to provide a username and email address.
        </Paragraph>
        <Title level={2}>Cookies</Title>
        <Paragraph>
          To enrich and perfect your online experience, Life Matrix uses
          &quot;Cookies&quot;, similar technologies and services provided by
          others to display personalized content, appropriate advertising and
          store your preferences on your computer.
        </Paragraph>
        <Paragraph>
          A cookie is a string of information that a website stores on a
          visitor&apos;s computer, and that the visitor&apos;s browser provides
          to the website each time the visitor returns. Life Matrix uses cookies
          to help Life Matrix identify and track visitors, their usage of
          https://lifematrix.me, and their website access preferences. A Plus
          Scheduler visitors who do not wish to have cookies placed on their
          computers should set their browsers to refuse cookies before using Geo
          Help&apos;s websites, with the drawback that certain features of Geo
          Help&apos;s websites may not function properly without the aid of
          cookies.
        </Paragraph>
        <Paragraph>
          By continuing to navigate our website without changing your cookie
          settings, you hereby acknowledge and agree to Life Matrix&apos;s use
          of cookies.
        </Paragraph>
        <Title level={2}>Credit & Contact Information</Title>
        <Paragraph>
          This privacy policy was created at{' '}
          <a
            style={{ color: 'inherit', textDecoration: 'none' }}
            href="https://termsandconditionstemplate.com/privacy-policy-generator/"
            title="Privacy policy template generator"
            rel="noopener noreferrer"
            target="_blank"
          >
            termsandconditionstemplate.com
          </a>
          . If you have any questions about this Privacy Policy, please contact
          us via <a href="mailto:misha.kav@gmail.com">email</a> or{' '}
          <a href="tel:+972528323072">phone</a>.
        </Paragraph>
      </Card>
    </>
  );
}

export default PrivacyPolicyPage;
