import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import en_US from 'antd/es/locale/en_US';
import ru_RU from 'antd/es/locale/ru_RU';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { App } from './containers/App';
import { getCurrentLanguage } from './utils/language';
import reportWebVitals from './utils/reportWebVitals';
import './i18n';

const currentLanguage = getCurrentLanguage();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 5 * 60 * 1000,
    },
  },
});

// init ANT DESIGN language
let locale = en_US;
if (currentLanguage === 'ru') {
  locale = ru_RU;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <HelmetProvider>
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </QueryClientProvider>
    </Suspense>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
