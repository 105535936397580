import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, Outlet } from 'react-router-dom';
import { auth } from '../../api/Firebase';
import { PAGES } from '../../utils/consts';

export const HomeLayout = () => {
  const [user, loading] = useAuthState(auth);

  if (user && !loading) {
    return <Navigate to={PAGES.HOME} />;
  }

  return <Outlet />;
};
