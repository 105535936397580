export const PAGES = Object.freeze({
  MAIN: '/',
  HOME: '/records',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  ABOUT: '/about',
  PRIVACY: '/privacy',
  SETTINGS: '/settings',
  RECORDS: '/records',
  NEW_RECORD: '/records/new',
  LEGEND_RECORD: '/records/legend',
  getRecordPage: (recordId?: string) =>
    recordId ? `/records/${recordId}` : '/records',
  getRecordEditPage: (recordId?: string) =>
    recordId ? `/records/edit/${recordId}` : '/records',
});

export const QUERIES = Object.freeze({
  USER_RECORDS: 'USER_RECORDS',
});
