import { useState } from 'react';
import {
  InfoCircleOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  UserOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu, Layout, Row, Col, Drawer, Typography, Avatar } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, logout } from '../../api/Firebase';
import styles from '../../scss/_colors.scss';
import { PAGES } from '../../utils/consts';
import { LanguageMenu } from './LanguageMenu';

const { Text } = Typography;

export function HeaderMobile() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [visible, setVisible] = useState(false);
  const { displayName, photoURL, email } = user?.providerData?.length
    ? user.providerData[0]
    : { displayName: '', photoURL: '', email: '' };

  const style = { fontSize: '16px' };
  const aboutItem: ItemType = {
    key: PAGES.ABOUT,
    icon: <InfoCircleOutlined />,
    style,
    label: (
      <Link to={PAGES.ABOUT} onClick={() => setVisible(false)}>
        {t('menu.about')}
      </Link>
    ),
  };

  const menuItems: MenuProps['items'] = [
    {
      key: 'profile',
      disabled: true,
      style: { cursor: 'default', height: 50 },
      label: (
        <Row>
          <Col style={{ marginRight: 20 }}>
            <Avatar
              size="large"
              src={photoURL}
              icon={<UserOutlined style={{ fontSize: 24 }} />}
              style={{ backgroundColor: styles.primary, marginTop: 10 }}
            />
          </Col>
          <Col>
            <Text strong>{displayName}</Text>
            <br />
            <Text type="secondary" style={{ position: 'relative', top: -20 }}>
              {email}
            </Text>
          </Col>
        </Row>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: PAGES.RECORDS,
      icon: <UnorderedListOutlined />,
      style,
      onClick: () => setVisible(false),
      label: (
        <Link to={PAGES.RECORDS}>
          <Text>{t('menu.records')}</Text>
        </Link>
      ),
    },
    aboutItem,
    {
      type: 'divider',
    },
    {
      key: 'logout',
      style,
      icon: (
        <Text type="danger">
          <LogoutOutlined />
        </Text>
      ),
      onClick: async () => {
        setVisible(false);
        await logout();
        navigate('/');
      },
      label: <Text strong>{t('menu.logout')}</Text>,
    },
  ];

  return (
    <>
      <Layout.Header style={{ padding: '0 10px', height: 44 }}>
        <Row justify="space-between">
          <Col>
            <div style={{ marginTop: -10 }}>
              <LanguageMenu />
            </div>
          </Col>

          <Col style={{ lineHeight: '42px' }}>
            <Link to={PAGES.MAIN} onClick={() => setVisible(false)}>
              <img
                width={35}
                height={35}
                src="/images/logo_250.png"
                alt={t('title.name')}
                style={{ marginTop: -5, marginRight: 10 }}
              />
              <Text style={{ color: 'white', fontSize: '24px' }}>
                {t('title.name')}
              </Text>
            </Link>
          </Col>

          <Col style={{ lineHeight: '50px' }}>
            <MenuOutlined
              onClick={() => setVisible(true)}
              style={{ color: '#fff', fontSize: '24px', padding: '2px' }}
            />
          </Col>
        </Row>
      </Layout.Header>

      {visible && (
        <Drawer
          bodyStyle={{ padding: 0 }}
          title={
            <Link to={PAGES.MAIN} onClick={() => setVisible(false)}>
              <img
                width={30}
                src="/images/logo_250.png"
                alt={t('title.name')}
                style={{ marginTop: -5 }}
              />
              <Text type="primary" style={{ fontSize: '20px', marginLeft: 10 }}>
                {t('title.name')}
              </Text>
            </Link>
          }
          closable={false}
          onClose={() => setVisible(false)}
          width="70vw"
          open={visible}
        >
          <Menu
            mode="vertical"
            selectedKeys={[pathname]}
            items={user ? menuItems : [aboutItem]}
          />
        </Drawer>
      )}
    </>
  );
}

export default HeaderMobile;
