import { Layout, Row, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import p from '../../../package.json';
import { PAGES } from '../../utils/consts';

const { Text } = Typography;

export function Footer() {
  const { t } = useTranslation();

  return (
    <Layout.Footer style={{ padding: '0 12px' }}>
      <Row justify="center">
        <Link to={PAGES.ABOUT}>{t('menu.about')}</Link>

        <Divider type="vertical" />

        <Link to={PAGES.PRIVACY}>
          <Text type="secondary" style={{ fontSize: '75%' }}>
            v.{p.version}
          </Text>
        </Link>
      </Row>
    </Layout.Footer>
  );
}

export default Footer;
