import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { mean } from 'lodash-es';
import {
  AXIS_TYPES,
  AxisType,
  Record,
  RecordStats,
  RecordStatus,
} from '../types.d';

dayjs.extend(duration);
dayjs.extend(relativeTime);

const formatDate = (
  date: dayjs.ConfigType,
  format = 'YYYY-MM-DD',
  originalFormat?: dayjs.OptionType
) => {
  if (date) {
    return dayjs(date, originalFormat).format(format);
  }

  return date;
};

const humanize = (date: dayjs.ConfigType) => {
  const end = dayjs(date);
  const duration = dayjs.duration(end.diff(dayjs())).asMinutes();
  return dayjs.duration(duration, 'minutes').humanize(true);
};

const getDiff = (
  startDate: dayjs.ConfigType,
  endDate = new Date(),
  units: dayjs.OpUnitType = 'minutes'
) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return end.diff(start, units);
};

// https://gist.github.com/4ndrej/33ac749d26b93b230269d8f93de7ced3
const mobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () =>
    mobile.Android() ||
    mobile.BlackBerry() ||
    mobile.iOS() ||
    mobile.Opera() ||
    mobile.Windows(),
  none: () => !mobile.any(),
};

const isMobile = () => mobile.any() != null;

const uuidv4 = () => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const shortUuid = () => uuidv4().slice(0, 8);

const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

const removeUndefineds = (obj: { [key: string]: any }) => {
  if (!obj) {
    return obj;
  }

  const copy = deepCopy(obj);

  Object.keys(copy).forEach((key) => {
    const recKey = key as string;
    if (copy[recKey] === undefined) {
      delete copy[recKey];
    }
  });

  return copy;
};

const getPercentageRatio = (num1: number, num2: number) => {
  if (!num1 || !num2) {
    return 0;
  }

  return ((num1 - num2) / num1) * 100;
};

const isLocalhost = () => window.location.href.includes('localhost');

const isStaging = () => window.location.href.includes('staging');

const isDevelopment = () => isStaging() || isLocalhost();

const AXIS_COLORS = {
  love: '#E33F44',
  wealth: '#389671',
  hobby: '#7a3f7a',
  friendship: '#1d948d',
  health: '#e4ba00',
  work: '#e36814',
  default: '#ddd',
};

const LEGEND_LIST: RecordStatus[] = [
  {
    title: 'excellent',
    range: '0-1',
    color: '#3dcf46',
  },
  {
    title: 'good',
    range: '2-3',
    color: '#99b13e',
  },
  {
    title: 'satisfactorily',
    range: '4-5',
    color: '#f59236',
  },
  {
    title: 'badly',
    range: '6-7',
    color: '#f55a32',
  },
  {
    title: 'critical',
    range: '8-9',
    color: '#f5222d',
  },
];

const getAxisColor = (type: AxisType) =>
  AXIS_COLORS[type] ?? AXIS_COLORS['default'];

const getRecordStats = (record: Record): RecordStats => {
  if (!record) {
    return { min: 0, max: 0, average: 0, diff: 0, status: LEGEND_LIST.at(-1)! };
  }

  const arrValues = AXIS_TYPES.map((axis) => record[axis]);
  const average = mean(arrValues);
  const min = Math.min(...arrValues);
  const max = Math.max(...arrValues);
  const diff = max - min;
  const status =
    LEGEND_LIST.find((l) => l.range.includes(diff.toString())) ||
    LEGEND_LIST.at(-1);

  return { min, max, average, diff, status: status! };
};

const DRAWER_WIDTH =
  isMobile() && window.matchMedia('(orientation: portrait)').matches
    ? window.screen.width
    : Math.min(window.screen.width, 402);

export {
  formatDate,
  humanize,
  getDiff,
  isMobile,
  uuidv4,
  shortUuid,
  deepCopy,
  removeUndefineds,
  getPercentageRatio,
  isLocalhost,
  isStaging,
  isDevelopment,
  getAxisColor,
  getRecordStats,
  DRAWER_WIDTH,
  LEGEND_LIST,
};
