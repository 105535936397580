import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, Outlet } from 'react-router-dom';
import { auth } from '../../api/Firebase';
import { PAGES } from '../../utils/consts';

export const ProtectedLayout = () => {
  const [user, loading] = useAuthState(auth);

  if (!loading && !user) {
    return <Navigate to={PAGES.LOGIN} />;
  }

  return <Outlet />;
};
