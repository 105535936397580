import { Menu, Dropdown, Avatar, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { getLanguageFlag, getCurrentLanguage } from '../../utils/language';
import { Language, languageFlags } from '../../utils/language';
import { isMobile } from '../../utils/misc';

const { Text } = Typography;

export function LanguageMenu() {
  const { t, i18n } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const languageIcon = (code: Language) => (
    <img
      style={{ width: 20, marginTop: -6, marginRight: 5 }}
      src={getLanguageFlag(code)}
      alt={code}
    />
  );

  const changeLanguage = (code: Language) => {
    localStorage.setItem('language', code);
    i18n.changeLanguage(code);

    // just to correct load of rtl in config provider and dayjs locale
    // window.location.reload();
  };

  const langugeMenu = Object.keys(languageFlags).map((code) => ({
    key: code,
    label: (
      <span>
        {languageIcon(code as Language)}
        {t(`language.${code}`)}
      </span>
    ),
  }));

  const menu = (
    <Menu
      onClick={({ key }) => changeLanguage(key as Language)}
      items={langugeMenu}
    />
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <span>
        {!isMobile() && (
          <Text style={{ color: 'white', fontSize: '16px', marginRight: 5 }}>
            {t(`language.${currentLanguage}`)}
          </Text>
        )}

        <Avatar
          src={getLanguageFlag(currentLanguage)}
          shape="square"
          style={{ width: 27, height: 20 }}
        />
      </span>
    </Dropdown>
  );
}

export default LanguageMenu;
