import { ReactNode } from 'react';
import { Layout } from 'antd';
import { Footer } from '../../components/Layout/Footer';
import { Header } from '../../components/Layout/Header';
import { HeaderMobile } from '../../components/Layout/HeaderMobile';
import { isMobile } from '../../utils/misc';

interface PrimaryLayoutProps {
  children: ReactNode;
}

export function PrimaryLayout(props: PrimaryLayoutProps) {
  const { children } = props;

  return (
    <Layout
      style={{
        minHeight: '100vh',
        backgroundImage: 'url(/images/background-image.webp)',
        backgroundSize: 'cover',
      }}
    >
      {isMobile() && <HeaderMobile />}
      {!isMobile() && <Header />}

      <Layout style={{ marginTop: 20, backgroundColor: 'transparent' }}>
        <Layout.Content>{children}</Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default PrimaryLayout;
